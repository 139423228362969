import React from "react"
import { graphql } from "gatsby"
import CardsContainer from "@global/cards/CardsContainer"
import Seo from "@elements/Seo"
import Cta from "@global/cards/Cta"
import Layout from "@layouts/layout"
import Block from "@global/layout/Block"
import { useAuth0 } from "@utils/auth"
import { ProtectedRoute } from "../components/ProtectedRoute"
import Content from "@elements/Content"

const Deals = ({ data, pageContext: { title, parent } }) => {
  const { region } = useAuth0()
  const deals = data.deals.nodes

  return (
    <ProtectedRoute>
      <Layout region={region} title={title} parent={parent}>
        <Seo
          title={data.content.deals.meta.title}
          description={data.miscContent.dealsIntroText}
        />
        <div>
          <Block padding="double-top" gutters={true} maxWidth="inner">
            <h1>{data.miscContent.dealsHeading}</h1>
            <Content html={`<p>${data.miscContent.dealsIntroText}</p>`} />
          </Block>
          <Block padding="both" gutters={true}>
            <CardsContainer modifier={"ctas"}>
              {deals.map(deal => {
                const { title, path } = deal

                return (
                  <>
                    <Cta
                      key={`deal-${deal.id}`}
                      heading={deal.title}
                      subHeading={deal.code}
                      image={deal.image}
                      link={{ title, path }}
                    />
                  </>
                )
              })}
            </CardsContainer>
          </Block>
        </div>
      </Layout>
    </ProtectedRoute>
  )
}

export default Deals

export const query = graphql`
  query MemberDeals($language: String, $locale: String) {
    deals: allDatoCmsDeal(
      filter: { locale: { eq: $language }, country: { in: [$locale, "Both"] } }
    ) {
      nodes {
        title
        id
        slug
        path
        code
        image {
          gatsbyImageData(imgixParams: { fit: "crop", w: "770", h: "460" })
        }
      }
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      deals {
        content
        meta {
          title
          desc
        }
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
    miscContent: datoCmsMiscContent {
      dealsIntroText
      dealsHeading
    }
  }
`
